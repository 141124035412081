import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Layout from "../components/layout"
import Category from "../components/category"

const CategoryTemplate = ({ data: { wp, posts } }) => {
  return (
    <Layout>
      <Seo
        title="Byers Scientific"
        postSchema={JSON.parse(wp.seo.contentTypes.post.schema.raw)}
      />
      <Category data={posts} />
    </Layout>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryBySlug(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $slug: String!
  ) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    # selecting the current page by id
    posts: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      edges {
        node {
          title
          slug
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
          }
          tags {
            nodes {
              name
            }
          }
          categories {
            nodes {
              slug
              uri
              name
            }
          }
          acfResource {
            file {
              guid
            }
          }
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
